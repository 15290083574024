import { Button, Modal } from 'antd';
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React, { useState } from "react";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import Tag from '../v2023/UI/Tag/tag';
import LinkButton from '../v2023/UI/LinkButton/link-button';

export const DemoSteps = ({demoSteps, category, children}) => {
    const [stepSelected, setStepSelected] = useState(demoSteps[0])
    const [showStepModal, setShowStepModal] = useState(false)
    const [stepIndexPulse, setStepIndexPulse] = useState(0)
    const [flowIndexPulse, setFlowIndexPulse] = useState(0)

    const openStepModal = (index) => {
        setStepSelected(demoSteps[index])
        setShowStepModal(true)
        setStepIndexPulse(index !== (demoSteps.length - 1) ? index + 1 : index)
        setFlowIndexPulse(0)
    }

    return (
        <div className="browser-image">
			{children}
            <div className={`browser-image__buttons browser-image__buttons--${category}`}>
                {demoSteps.map((item, index) => <button
                        key={index}
                        className={`button-icon button-icon--number ${index === stepIndexPulse ? "button-icon--number--pulse" : ""}`}
                        onClick={() => openStepModal(index)}>
                        {item.order}
                    </button>
                )}
                <Modal
                    centered
                    onCancel={() => setShowStepModal(false)}
                    visible={showStepModal}
                    title={<>
                        {stepSelected.tag &&
							<div class="tag tag--status">
                                <i class="icon-rocket"></i>
                                {stepSelected.tag.name}
                            </div>

						}
                        <h3>{stepSelected.title}</h3>
                        <ReactMarkdown children={stepSelected.description} rehypePlugins={[rehypeRaw]} />
                        {stepSelected.button &&
							<LinkButton className="button" button={stepSelected.button} />
						}

                        {/* {stepSelected.image_button && <ImageButton button={stepSelected.image_button} />} */}
                        {/* {stepSelected.order === 5 && <LinkButton button={page.button_open_gateway} className="button" />} */}
                    </>}
                    width={"100%"}
                    footer={null}
                    className="ant-modal--green"
                >
                    <GatsbyImage alt={stepSelected.icon?.alternativeText  || stepSelected.image.alternativeText } image={getImage(stepSelected.icon?.localFile || stepSelected.image.localFile)} />
                </Modal>
            </div>
        </div>
    )
}

import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';

export const ResponsiveImage = ({ image1440 = null, image992 = null, image768 = null, image576 = null }) => {
	return (
		<>
			{image1440 && <div className="hidden-md">
				<GatsbyImage
					alt={image1440.alternativeText}
					image={getImage(image1440.localFile)}
				/>
			</div>}
			
			{image992 && <div className="show-md hidden-sm">
				<GatsbyImage
					alt={image992.alternativeText}
					image={getImage(image992.localFile)}
				/>
			</div>}

			{image768 && <div className="show-sm hidden-xs">
				<GatsbyImage
					alt={image768.alternativeText}
					image={getImage(image768.localFile)}
				/>
			</div>}

			{image576 && <div className="show-xs">
				<GatsbyImage
					alt={image576.alternativeText}
					image={getImage(image576.localFile)}
				/>
			</div>}
		</>
	);
};

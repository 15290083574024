import React from 'react';
import NewLayout from '../components/new_layout';
import { graphql } from 'gatsby';
import SEO from '../components/seo';
import { MainSection100, MainSection50 } from '../components/Layout/sections';
import { Container, Container50 } from '../components/Layout/containers';
import Tag from '../components/v2023/UI/Tag/tag';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import LinkButton from '../components/v2023/UI/LinkButton/link-button';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { DemoSteps } from '../components/v2024/demo-steps';
import ImageGroup from '../components/v2024/image/image-group';
import ItemGroup from '../components/v2023/UI/Item/item-group';
import { SectionTools } from '../components/v2024/section-tools';
import ReadyToTalk from '../components/v2023/ReadyToTalk/ready-to-talk';
import { ResponsiveImage } from "../components/v2024/responsive-image";

const KiosksPage = ({ data, pageContext }) => {
	const page = data.page;
	const lang = pageContext.langKey;
    const baseUrl = '';
	return (
		<NewLayout pageContext={pageContext} lang={pageContext?.langKey}>
			<SEO
				lang={lang}
				title={page.seo.title}
				description={page.seo.meta_description}
				translates={pageContext.translates}
                image={page.seo?.image_2?.localFile?.publicURL}
			/>
			<main className="main">
				<MainSection100 sectionType="color" sectionColor="purple-dark">
					<Container animation="fade-up">
						{page.hero_tag.map((tag, index) => (
							<Tag tag={tag} key={index} />
						))}
                        <div className="container__text">
						    <h1 className="merriweather">{page.hero_title}</h1>
						    <ReactMarkdown children={page.hero_description} rehypePlugins={[rehypeRaw]} />
                            <div className="button__wrapper">
						        <LinkButton button={page.hero_button} className="button button--ghost button--xl" />
                            </div>
                        </div>
                    </Container>
                    <Container np animation="fade-up">
						<DemoSteps demoSteps={page.hero_demo_steps} category="kiosks">
                            <ResponsiveImage image1440={page.hero_image_xl} image992={page.hero_image_xl} image768={page.hero_image_xl} image576={page.hero_image_sm} />
                        </DemoSteps>
                    </Container>
                    <Container animation="fade-up">
                        <div className='grid-lg-4'>
                        {page.hero_items.map((item, index) => {
                            const animation = { "data-aos": "zoom-in", "data-aos-delay": 200 * index };
                            return <ImageGroup basicInfoWithImage={item} key={index} animation={animation} />;
                        })}
                        </div>
					</Container>
				</MainSection100>
                <MainSection100>
                    <Container>
                        <h2>{page.kiosk_title}</h2>
                        <div className='grid-lg-3-h'>
                            {page.kiosk_items.map((item, index) => (
                                <ImageGroup basicInfoWithImage={item} key={index} />
                            ))}
                        </div>
                    </Container>
                </MainSection100>
                <MainSection100>
                    <Container>
                        <h2>{page.use_case_title}</h2>
                        <ItemGroup items={page.use_case_items} hasImage unboxed />
                    </Container>
                </MainSection100>
                <SectionTools title={page.capacities_title} tools={page.capacities_items} />
                <MainSection100 sectionType="color" sectionColor="gray  gray--noframe">
                    <Container>
                        <Tag tag={{name: page.software_tag, type: 'Info'}} />
                        <h2>{page.software_title}</h2>
                        <div className="container__text">
                            <ReactMarkdown children={page.software_description} rehypePlugins={[rehypeRaw]} />
                        </div>
                        <div className='grid-lg-4'>
                            {page.software_feature.map((item, index) => {
                                const isLast = index === page.software_feature.length - 1;
                                const animation = { "data-aos": "zoom-in", "data-aos-delay": 200 * index };
                                return <div className={`tile-icon ${isLast ? "tile-icon--highlight" : ""}`} {...animation} key={index}>
                                    <div className="tile-icon__image">
                                        <GatsbyImage
                                            alt={item.image.alternativeText}
                                            image={getImage(item.image.localFile)}
                                            placeholder="blurred"
                                            quality={100}
                                        />
                                    </div>
                                    <div className="tile-icon__body">
                                        <h5>{item.title}</h5>
                                        <span>
                                            <ReactMarkdown children={item.description} rehypePlugins={[rehypeRaw]} />
                                        </span>
                                    </div>
                                    {item.button?.text && item.button?.url && (
                                        <div className="tile-icon__action">
                                            <LinkButton button={item.button} className={"link--big"} extras={<i className="icon-arrow-right"></i>} />
                                        </div>
                                    )}
                                </div>
                            })}
                        </div>
                    </Container>
                </MainSection100>
                <MainSection50>
                    <Container>
                        <Container50>
                            <h1>{page.unique_terminal.title}</h1>
                            <ReactMarkdown children={page.unique_terminal.description} rehypePlugins={[rehypeRaw]} />
                        </Container50>
                        <Container50>
                            {/* <Container> */}
                                <GatsbyImage alt={page.unique_terminal.image.alternativeText} image={getImage(page.unique_terminal.image.localFile)} />
                            {/* </Container> */}
                        </Container50>
                    </Container>
                </MainSection50>
                <MainSection100>
                    <Container>
                    <div className='hidden-sm'>
                        <GatsbyImage alt={page.logos_image_lg.alternativeText} image={getImage(page.logos_image_lg.localFile)} />
                    </div>
                    <div className='show-sm'>
                        <GatsbyImage alt={page.logos_image_sm.alternativeText} image={getImage(page.logos_image_sm.localFile)} />
                    </div>
                    </Container>
                </MainSection100>
                <MainSection50 invert>
                    <Container>
                        <Container50>
                            <h1>{page.accessibility.title}</h1>
                            <ReactMarkdown children={page.accessibility.description} rehypePlugins={[rehypeRaw]} />
                        </Container50>
                        <Container50>
                            {/* <Container> */}
                                <GatsbyImage alt={page.accessibility.image.alternativeText} image={getImage(page.accessibility.image.localFile)} />
                            {/* </Container> */}
                        </Container50>
                    </Container>
                </MainSection50>
                <ReadyToTalk lang={lang} />
			</main>
		</NewLayout>
	);
};

export default KiosksPage;

export const kiosksQuery = graphql`
	query ($langKey: String) {
		page: strapi2024Kioskos(locale: { eq: $langKey }) {
			seo {
				title
				meta_description
                image_2 {
                    url
                    name
                    alternativeText
                    localFile {
                        publicURL
                        childImageSharp {
                            gatsbyImageData(
                                placeholder: BLURRED
                                formats: [WEBP]
                            )
                        }
                    }
                }
			}
			hero_tag {
				name
				type
			}
			hero_title
			hero_description
			hero_button {
				text
				url
				blank
			}
			hero_image_xl {
				name
				alternativeText
				localFile {
					childImageSharp {
						gatsbyImageData(width: 934, placeholder: BLURRED, formats: [WEBP], breakpoints: [768])
					}
				}
			}
			hero_image_sm {
				name
				alternativeText
				localFile {
					childImageSharp {
						gatsbyImageData(width: 704, placeholder: BLURRED, formats: [WEBP])
					}
				}
			}
			hero_demo_steps {
				order
				title
				description
				image {
					name
					alternativeText
					localFile {
						childImageSharp {
							gatsbyImageData(width: 768, placeholder: BLURRED, formats: [WEBP])
						}
					}
				}
			}
            hero_items {
                description
                image {
                    name
                    alternativeText
                    localFile {
                        childImageSharp {
                            gatsbyImageData(
                                width: 80
                                placeholder: BLURRED
                                formats: [WEBP]
                            )
                        }
                    }
                }
            }
            kiosk_title
            kiosk_items {
                title
                description
                image {
                    name
                    alternativeText
                    localFile {
                        childImageSharp {
                            gatsbyImageData(
                                width: 430
                                placeholder: BLURRED
                                formats: [WEBP]
                            )
                        }
                    }
                }
            }
            use_case_title
            use_case_items {
                id
                title
                description
                icon {
                    name
                    alternativeText
                    localFile {
                        childImageSharp {
                            gatsbyImageData(
                                width: 608
                                placeholder: BLURRED
                                formats: [WEBP]
                                breakpoints: [512]
                            )
                        }
                    }
                }
            }
            capacities_title
            capacities_items {
                id
                title
                description
                image {
                    name
                    alternativeText
                    localFile {
                        childImageSharp {
                            gatsbyImageData(
                                width: 608
                                placeholder: BLURRED
                                formats: [WEBP]
                                breakpoints: [512]
                            )
                        }
                    }
                }
            }
            software_tag
            software_title
            software_description
            software_feature{
                title
                description
                image {
                    name
                    alternativeText
                    localFile {
                        childImageSharp {
                            gatsbyImageData(
                                width: 64
                                placeholder: BLURRED
                                formats: [WEBP]
                            )
                        }
                    }
                }
                button{
                    text
                    url
                    blank
                }
            }
            unique_terminal {
                title
                description
                image {
                    name
                    alternativeText
                    localFile {
                        childImageSharp {
                            gatsbyImageData(
                                width: 1118
                                placeholder: BLURRED
                                formats: [WEBP]
                                breakpoints: [512, 608]
                            )
                        }
                    }
                }
            }
            accessibility {
                title
                description
                image {
                    name
                    alternativeText
                    localFile {
                        childImageSharp {
                            gatsbyImageData(
                                width: 1118
                                placeholder: BLURRED
                                formats: [WEBP]
                                breakpoints: [512, 608]
                            )
                        }
                    }
                }
            }
            logos_image_lg {
                name
                alternativeText
                localFile {
                    childImageSharp {
                        gatsbyImageData(
                            width: 1376
                            placeholder: BLURRED
                            formats: [WEBP]
                        )
                    }
                }
            }
            logos_image_sm {
                name
                alternativeText
                localFile {
                    childImageSharp {
                        gatsbyImageData(
                            width: 704
                            placeholder: BLURRED
                            formats: [WEBP]
                        )
                    }
                }
            }
		}
	}
`;
